.Cover_Home {
  width: 100%;
  height: 725px;
  background-image: url("https://res.cloudinary.com/dshxqbjrf/image/upload/v1707883358/desktop_ifd7lb.png");
  background-color: blueviolet;
  border-width: 0px;
  background-position: center;
  /* border-bottom-width: 10px; */
  border-style: solid;
  border-color: rgb(231, 231, 231);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .Cover_Home {
    background-image: url("https://res.cloudinary.com/dshxqbjrf/image/upload/v1719855595/hero_task_bg_c7ygxz.png");
  }
}

.Cover_Feed {
  width: 100%;
  height: 300px;
  background-image: url("https://res.cloudinary.com/collabizm/image/upload/v1648828553/pi_opacity.png");
  background-color: blueviolet;
  border-width: 0px;
  border-bottom-width: 10px;
  border-style: solid;
  border-color: rgb(231, 231, 231);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Nestomir {
  background-origin: padding-box;
  background-size: 225px;
  background-image: url("https://res.cloudinary.com/dshxqbjrf/image/upload/v1633998380/chapter_symbol_sfj0ji.png");
  background-repeat: repeat-x;
}

.Modal {
  position: absolute;
  background: #fff;
  overflow: auto;
  width: 37.5%;
  -webkit-overflow-scrolling: "touch";
  outline: none;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: auto;
  margin-right: -35%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #919191;
  padding: 0;
}

.Modal2 {
  position: absolute;
  background: #fff;
  overflow: auto;
  height: 50%;
  width: 37.5%;
  -webkit-overflow-scrolling: "touch";
  outline: none;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: auto;
  margin-right: -35%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  border-width: 0.5px;
  border-style: solid;
  border-color: #919191;
  padding: 0;
}

@media only screen and (max-width: 1000px) {
  .Modal {
    width: 87%;
  }
  .Modal2 {
    width: 87%;
  }
}

@media only screen and (max-width: 480px) {
  .Modal {
    width: 97%;
  }
  .Modal2 {
    width: 97%;
  }
}

.Modal_Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 20%;
  background-color: #f9f9f9;
  border-radius: 4px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  padding-bottom: 23px;
}

.dropdown-content a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: rgb(85, 26, 139);
}
.dropdown:hover .dropdown-content {
  display: block;
}

.text-gradient-america {
  background: linear-gradient(to right, #543aab 0%, #e06a33 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-weight: 900;
}

.text-gradient1 {
  background: linear-gradient(to right, #543aab 0%, #49a6d5 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-weight: 900;
}

.text-gradient2 {
  background: linear-gradient(to right, #543aab 0%, #af8686 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-weight: 900;
}

.text-gradient3 {
  background: linear-gradient(to right, #543aab 0%, #e47194 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-weight: 900;
}

.text-gradient4 {
  background: linear-gradient(to right, #543aab 0%, #49a6d5 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-weight: 900;
}

.flash {
  height: 26px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12.5px;
  background-color: rgb(223, 223, 223);
  animation: pulse 1500ms infinite;
}

.flash_off {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12.5px;
}

@keyframes pulse {
  0% {
    box-shadow: #8cd9d7 0 0 0 0;
  }

  75% {
    box-shadow: #d7d5d500 0 0 0 16px;
  }
}

aside {
  padding: 25px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 200;
  background: radial-gradient(circle, rgb(149 134 134) 24%, rgb(143 128 185) 78%);
  color: white;
  padding: 15px;
  line-height: 29px;
  border-radius: 5px;
}

h3 {
  font-size: 25px;
  font-family: "Roboto Serif", sans-serif;
  margin-top: 40px;
}

p,
ul {
  line-height: 35px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 300;
}

.TestBG {
  display: flex;
  justify-content: center;
  background-size: 150px;
  background-image: url("https://res.cloudinary.com/dshxqbjrf/image/upload/v1678476897/FULL_AXE_vn7o6h.png");
  background-repeat: no-repeat;
  background-color: #f8f0fb;
  /* background: radial-gradient(circle, #ede9f9 24%, rgb(251, 240, 248) 78%); */
}

blockquote {
  padding: 25px;
  font-family: "Roboto Serif", sans-serif;
  font-weight: 200;
  font-size: 20px;
  /* background: radial-gradient(circle, rgb(130 63 100) 24%, rgb(63 52 92) 78%); */
  color: rgb(33, 33, 33);
  padding: 15px;
  line-height: 35px;
  border-radius: 5px;
}

tr {
  font-family: "Roboto Serif", sans-serif;
  font-weight: 200;
}

.highlight-container,
.highlight {
  position: relative;
  color: white;
  font-family: "Roboto Serif", sans-serif;
}

.highlight-container {
  display: inline-block;
  padding: 10px;
}
.highlight-container:before {
  content: " ";
  display: block;
  height: 90%;
  width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  background: #aa00ff;
  transform: rotate(2deg);
  top: -1px;
  left: -1px;
  border-radius: 20% 25% 20% 24%;
  padding: 10px 3px 3px 10px;
  border-style: solid;
  border-width: 3px;
  border-color: #6d54bd;
  background: radial-gradient(circle, rgb(149 134 134) 24%, rgb(143 128 185) 78%);
}
